import React, { useState } from 'react';

export function MainPage(props){
    const [roomName, setRoomName] = useState("");

    return (
        <div className='container'>
            <div className='row'>
                <h1>TeleTrust</h1>
            </div>
            <div className='row'>
                <div className="input-group mb-3 mx-auto col-3">
                    <input type="text" className="form-control" placeholder="Szoba kód" value={roomName} onChange={event=>setRoomName(event.currentTarget.value)}/>
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button" onClick={()=>{window.location.href="/"+roomName;}}>Csatlakozás</button>
                    </div>
                </div>
            </div>
            
        </div>
       );

    
}
