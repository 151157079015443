import { VideoHTMLAttributes, useEffect, useRef } from 'react'


export function Video({ srcObject,userName,onClick,divClass, ...props }) {
  const refVideo = useRef(null);

  useEffect(() => {
    if (!refVideo.current) return
    refVideo.current.srcObject = srcObject
  }, [srcObject]);

  return (<div className={divClass} onClick={onClick}>

      <p style={{width: "100%", textAlign: "center", marginBottom: "5px"}}>{userName}</p>
    <video className='mh-100 mw-100 mx-auto' ref={refVideo} autoPlay {...props} />
      
      
    </div>);
}