import React, { useState, useEffect, useRef } from 'react';
import { socket } from "./socket";
import { useParams } from "react-router-dom";
import { DataConnection, Peer } from "peerjs";
import { PeerJsOptions } from './PeerJsOptions';
import { Video } from "./Video";

export function Call(props){
    const [peers, setPeers] = useState([]);
    const [status, setStatus] = useState("start");
    const [roomName, setRoomName] = useState(useParams().roomName);
    const [inputDevices, setInputDevices] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState("");
    const [selectedMicrophone, setSelectedMicrophone] = useState("");
    const [activePeer, setActivePeer] = useState("");
    const localPeer = useRef(null);
    const [userName, setUserName] = useState("");
    const userMediaStream = useRef(null);

    useEffect(()=>{
        if (localPeer.current === null && status === "peerjs") {
            var peer = new Peer(PeerJsOptions);
            peer.on('error', (error) => {
                console.error(error);
            });
            peer.on("open", id=>{
                console.log(id);
                setStatus("connecting");
                OnPeerJsConnected(id);
            });
            localPeer.current = peer;
        }

        if (status === "start") {
            navigator.mediaDevices.getUserMedia({video: true, audio: true})
                .then((stream) => {
                    setStatus("selectDevices");
                })
                .catch((err) => {
                    setStatus("start");
                });
        }

        if (status === "selectDevices") {
            navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {

                    setInputDevices(devices.filter(x=>x.kind === "audioinput" || x.kind === "videoinput"));
                /*devices.forEach((device) => {
                    console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
                });*/
                })
                .catch((err) => {
                console.error(`${err.name}: ${err.message}`);
                });
        }
    },[status]);

    useEffect(()=>{
        if ((activePeer === "" || peers.find(x=>x.key==activePeer) === undefined) && peers.length >= 1) {
            setActivePeer(peers[0].key); 
        }
    },[peers])


    if (status === "start") {
                
        return (<p>Please allow camera and microphone access</p>);
    }

    if (status === "selectDevices") {
        return (
            <>
                <label>
                    Kamera
                    <select value={selectedCamera} onInput={event=>{setSelectedCamera(event.currentTarget.value);}}>
                        {inputDevices.filter(x=>x.kind === "videoinput").map(x=><option key={x.deviceId} value={x.deviceId}>{x.label}</option>)}
                    </select>
                </label>
                <br/>
                <label>
                    Mikrofon
                    <select value={selectedMicrophone} onInput={event=>{setSelectedMicrophone(event.currentTarget.value);}}>
                        {inputDevices.filter(x=>x.kind === "audioinput").map(x=><option key={x.deviceId} value={x.deviceId}>{x.label}</option>)}
                    </select>
                </label>
                <br/>
                <label>
                    Felhasználónév
                    <input type='text' value={userName} onInput={event => setUserName(event.currentTarget.value)}/>
                </label>
                
                <button disabled={userName.trim() === ""} onClick={()=>setStatus("peerjs")}>Belépés</button>
            </>
        );
    }

    if(status === "peerjs"){
        
        return (<p>Connecting to PeerJs...</p>);
    }
    else if (status === "connecting") {
        return (<p>Connecting to signalling server...</p>);
    }
    else if(status === "joining"){

        return (<p>Joining...</p>);
    }

    
    
    const aP = peers.find(x=> x.key === activePeer);
    
    return (
    <div className='container'>
        <div className='row'>
            {aP?<div><Video srcObject={aP.video} userName={aP.name} divClass='mx-auto col-md-8 text-center' autoPlay/></div>:<></>} 
        </div>
        
        
        <div className='row'>
            {peers.filter(x=>x !== aP).map(p=><Video srcObject={p.video} userName={p.name} divClass='col-3' onClick={()=>setActivePeer(p.key)} key={p.key} autoPlay/>)}
        </div>
        
        <div className='row'>
            <button className='btn btn-outline-danger col mx-auto' onClick={()=>window.location.href = "/"}>Kilépés</button>
        </div>
    </div>
    );

    async function OnPeerJsConnected(id){
        socket.on("connected",()=>{
            OnSocketConnected(id);
        });

        socket.connect();

        setStatus("connecting");
    }

    function OnSocketConnected(id){
        socket.on("peerJsIdSet",OnPeerJsIdSet);
        socket.emit("setPeerJsId", id);
    }

    function HandlePeerMessage(connection,messageType, content){
        switch (messageType) {
            case "setName":
                setPeers(peers=>{
                    var ptochange = peers.find(x=>x.key===connection.peer);
                    if (ptochange === undefined) {
                        ptochange = {key: connection.peer, video: null, name: content};
                        return peers.concat(ptochange);
                    }
                    ptochange.name = content;
                    return peers.filter(x=>x.key!==ptochange.key).concat(ptochange);
                });
                
                break;
            default:
                break;
        }
    }

    function OnPeerJsIdSet(){
        const peer = localPeer.current;
        

        peer.on("connection", (conn) =>{
            conn.on("data",(data)=>{
                var payload=JSON.parse(data);
                HandlePeerMessage(conn,payload.type, payload.content);
            });
            conn.on("open",()=>{
                conn.send(JSON.stringify({type: "setName", content: userName}));
            });
        });
        
        // Handle incoming voice/video connection
        peer.on('call', (call) => {
            navigator.mediaDevices.getUserMedia({video: {deviceId: selectedCamera}, audio: { deviceId: selectedMicrophone}})
                .then((stream) => {
                    call.answer(stream); // Answer the call with an A/V stream.
                    call.on('stream', video => {
                        setPeers(peers=>{
                            var ptochange = peers.find(x=>x.key===call.peer);
                            if (ptochange === undefined) {
                                ptochange = {key: call.peer, video: video, name: null};
                                return peers.concat(ptochange);
                            }
                            ptochange.video = video;
                            return peers.filter(x=>x.key!==ptochange.key).concat(ptochange);
                        });
                    });
                })
                .catch((err) => {
                    console.error('Failed to get local stream', err);
                });
        });
        socket.on("peerConnect", OnPeerConnect);
        socket.on("peerDisconnect",OnPeerDisconnect);
        socket.emitWithAck("joinRoom",roomName).then(()=>{
            setStatus("joined");
        });
        setStatus("joining");
    }

    function OnPeerDisconnect(peerId){
        setPeers(peers=>peers.filter(x=>x.key!==peerId));
    }

    function OnPeerConnect(peerId){
        console.log(peerId+" connected");
        navigator.mediaDevices.getUserMedia({video: {deviceId: selectedCamera}, audio: { deviceId: selectedMicrophone}})
            .then((stream) => {
            let call = localPeer.current.call(peerId, stream);
            
            call.on('stream', video => {
                setPeers(peers=>{
                    var ptochange = peers.find(x=>x.key===call.peer);
                    if (ptochange === undefined) {
                        ptochange = {key: call.peer, video: video, name: null};
                        return peers.concat(ptochange);
                    }
                    ptochange.video = video;
                    return peers.filter(x=>x.key!==ptochange.key).concat(ptochange);
                });

                });
            })
            .catch((err) => {
            console.log('Failed to get local stream', err);
        });

        var dataConn = localPeer.current.connect(peerId, {serialization:"json", reliable: true});
        

        dataConn.on("data",(data)=>{
            var payload=JSON.parse(data);
            HandlePeerMessage(dataConn,payload.type, payload.content);
        });
        dataConn.on("open",()=>{
            dataConn.send(JSON.stringify({type: "setName", content: userName}));
        });
    }

}