import './App.css';
import { Call } from "./Call";
import { MainPage } from './MainPage';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {

  return (
    <BrowserRouter basename="">
      <Routes>
        <Route path="/" element={<MainPage/>}/>
        <Route path="/:roomName" element={<Call/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
